<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('close')"
      width="700"
      content-class="send-agreement"
    >
      <v-card class="send-agreement__content" v-if="hr" light>
        <div class="send-agreement__header">
          <div class="page-title">Gig Contract Signed Offsite</div>
          <v-btn class="close" icon @click="$emit('close')">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="send-agreement__body">
          <div v-if="!marked">
            <div class="send-agreement__msg">
              <p>
                If a contract for this hire request was signed in person or
                outside of the Community site, you can mark it as such here.
                Review the fields below to ensure this is accurate and matches
                the existing contract before continuing.
              </p>
              <FormRow light label="Contract ID:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.monday_com_item_id }}
                </div>
              </FormRow>
              <FormRow light label="Contractor’s Name:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.full_name }}
                </div>
              </FormRow>
              <FormRow light label="Title:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.role }}
                </div>
              </FormRow>
              <FormRow light label="Projects:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.projects }}
                </div>
              </FormRow>
              <!-- <FormRow light label="Project Code:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.project_code }}
                </div>
              </FormRow> -->
              <div class="send-agreement__rate-row">
                <FormRow light label="Rate Amount:">
                  <div class="hr__txt">
                    {{ hr.adobe_agreement_fields.rate_amount }}
                  </div>
                </FormRow>
                <FormRow light label="Rate of Pay:">
                  <div class="hr__txt">
                    {{ hr.adobe_agreement_fields.rate_of_pay }}
                  </div>
                </FormRow>
              </div>
              <FormRow light label="Additional Rate Information:">
                <div class="hr__txt hr__txt--breaks">
                  {{ hr.adobe_agreement_fields.rate_details }}
                </div>
              </FormRow>
              <FormRow light label="Budgeted Hours:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.estimated_hours }}
                </div>
              </FormRow>
              <FormRow light label="Hiring Manager:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.hiring_manager }}
                </div>
              </FormRow>
              <FormRow light label="Description of Services:">
                <div class="hr__txt hr__txt--breaks">
                  {{ hr.adobe_agreement_fields.job_description }}
                </div>
              </FormRow>
              <FormRow light label="Active Dates:">
                <div class="hr__txt">
                  {{
                    [
                      hr.adobe_agreement_fields.start_date,
                      hr.adobe_agreement_fields.end_date,
                    ]
                      .filter((d) => d)
                      .join("–")
                  }}
                </div>
              </FormRow>
              <!-- <FormRow light label="Contractor’s Address:">
                <div class="hr__txt">
                  {{ hr.adobe_agreement_fields.address_line_1 }}<br/>
                  {{ hr.adobe_agreement_fields.address_line_2 }}<br/>
                </div>
              </FormRow> -->
            </div>
            <div class="send-agreement__actions">
              <v-btn @click="markAsSignedOffsite" color="primary" elevation="0"
                >Mark as Signed Offsite</v-btn
              >
            </div>
          </div>
          <div v-else>
            <p>Gig contract marked as signed offsite.</p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    dialog: Boolean,
    hr: Object,
  },
  data() {
    return {
      marked: false,
    };
  },
  methods: {
    async markAsSignedOffsite() {
      try {
        const transaction_id = uuidv4();
        this.$store.commit("agreement/setTransactionId", transaction_id);
        const response = await this.$api.HireRequest.markAsSignedOffsite({
          id: this.hr.id,
          transaction_id,
        });
        if (response.status == 200) {
          this.marked = false;
          this.$emit("close");
          this.$emit("refresh");
        }
      } catch (e) {
        this.$alert(
          "Something went wrong. " + e.response.data.errors.join(" "),
          "error"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-agreement {
  &__content {
    padding: 17px 30px 30px;
  }

  &__title {
    color: #e12d1b;
  }
  &__msg {
    margin-bottom: 35px;
  }
  &__actions {
    .v-btn {
      color: white !important;
    }
  }

  &__name-in-body {
    font-weight: bold;
    white-space: nowrap;
  }
  &__rate-row {
    display: flex;
  }
}

.hr__txt {
  margin-top: 10px;
}
</style>

<style lang="scss">
.send-agreement {
  .form-row {
    padding: 5px 0 !important;
  }
  .form-label {
    min-width: 150px !important;
    max-width: 150px !important;
  }
}
</style>
