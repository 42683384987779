<template>
  <TagSelector
    :multiple="multiple"
    :items="list"
    :value="value"
    @input="$emit('input', $event)"
    itemText="title"
    itemValue="id"
    :placeholder="placeholder"
    :rules="rules"
    @change="$emit('change', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    placeholder: String,
    emptyLabel: String,
    params: Object,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.setInternalProjects();
  },
  methods: {
    async setInternalProjects() {
      const { data } = await this.$api.InternalProject.lite(this.params);
      this.list = [
        { title: this.emptyLabel || "N/A", id: null },
        ...data.map((t) => ({ ...t, title: `${t.title} (${t.code})` })),
      ];
    },
  },
};
</script>

<style></style>
