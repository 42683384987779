<template>
  <div class="finance-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">Payments Dashboard</div>
    <div>
      <div>
        <div class="finance__actions">
          <v-btn color="primary" @click="submitPRDialog = true" elevation="0">
            Pay Directly
          </v-btn>
          <v-btn
            to="/finance/all-projects"
            elevation="0"
            color="#FFBC10"
            style="color: white !important"
          >
            View All Projects
          </v-btn>
          <div>
            <form
              method="post"
              action="/api/v1/profile_side/invoices/csv"
              target="_blank"
            >
              <input
                type="hidden"
                name="token"
                :value="$store.getters['auth/token']"
              />
              <input type="hidden" name="all" value="true" />
              <v-btn
                type="submit"
                color="#56565A"
                elevation="0"
                style="color: white !important"
                >Export All Payments</v-btn
              >
            </form>
          </div>
          <div>
            <form
              method="post"
              action="/api/v1/profile_side/invoices/csv_itemized"
              target="_blank"
            >
              <input
                type="hidden"
                name="token"
                :value="$store.getters['auth/token']"
              />
              <input type="hidden" name="all" value="true" />
              <v-btn
                type="submit"
                color="#56565A"
                elevation="0"
                style="color: white !important"
                >Itemized Export</v-btn
              >
            </form>
          </div>
          <v-btn
            to="/payments-reports"
            elevation="0"
            color="#56565A"
            style="color: white !important"
          >
            Payments Reporting
          </v-btn>
        </div>
      </div>
    </div>
    <div :key="`reload-key-${reloadKey}`">
      <PaymentsSection
        title="Pending Payments"
        :key="`pending-section-${reloadKey}`"
        @refresh="reloadKey++"
      />
      <CompletedPaymentsSection
        title="Completed Payments"
        :key="`completed-section-${reloadKey}`"
        @refresh="reloadKey++"
      />
      <FinanceAllProjectsTable :items="projects" title="Recent Projects" />
    </div>
    <FiPayDirectlyDialog
      v-if="submitPRDialog"
      :dialog="submitPRDialog"
      @close="submitPRDialog = false"
      @refresh="refreshAll"
    />
  </div>
</template>

<script>
import PaymentsSection from "./payments-dashboard/section.payments-dashboard";
import CompletedPaymentsSection from "./payments-dashboard/completed-section.payments-dashboard";
import FiPayDirectlyDialog from "./FiPayDirectlyDialog";
import FinanceAllProjectsTable from "./all-projects/table.all-projects";

export default {
  name: "PaymentsPage",
  components: {
    PaymentsSection,
    CompletedPaymentsSection,
    FiPayDirectlyDialog,
    FinanceAllProjectsTable,
  },
  head: {
    title: "Payments Dashboard",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      submitPRDialog: false,
      reloadKey: 1,
      projects: [],
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "InvoiceFinanceChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      const { data } = await this.$api.InternalProject.financeList({
        recent: true,
      });
      this.projects = data;
    },
    refreshAll() {
      this.reloadKey++;
    },
    async handleInvoiceBroadcast(type, payload) {
      if (
        !payload.transaction_id ||
        payload.transaction_id != this.$store.getters["invoice/transactionId"]
      ) {
        this.refreshAll();
        this.$alert("Invoices updated.");
      }
    },
  },
  channels: {
    InvoiceFinanceChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.finance {
  margin-top: 50px;
  border-radius: 10px;

  &__header {
    padding: 25px 40px;
    background: white;
    border: 2px solid #262729;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 960px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    color: #e12d1b;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    > *:not(:last-child) {
      margin-right: 13px;
    }
    @media screen and (max-width: 960px) {
      > * {
        margin-bottom: 10px;
      }
    }
  }

  &-page {
    &__actions {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }
    &__action-btn {
      margin-left: 1em;
      font-size: 80%;
      font-weight: bold;
      margin-bottom: 0.5em;
      cursor: pointer;
    }
    &__action {
      width: 250px;
      margin-left: 1em;
      margin-bottom: 1em;
      &__label {
        font-weight: bold;
      }

      ::v-deep {
        .v-input fieldset {
          border-color: #262729;
          border-width: 2px;
        }
      }
    }
  }
}

.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
