<template>
  <div class="hm-dashboard">
    <div>
      <div class="page-title">Creator Payments</div>
    </div>
    <CreatorsSection
      v-if="myActiveCreators"
      :value="myActiveCreators"
      light
      hmActions
    />
    <div class="hm-dashboard__go-to">
      <div class="page-title">Go to All Payments</div>
      <router-link to="/payments">
        <v-icon color="white" size="58">$arrow_right</v-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myActiveCreators: null,
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "InvoiceHmChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    this.fetchMyActiveCreators();
  },
  methods: {
    async fetchMyActiveCreators() {
      const { data } = await this.$api.Creator.sections({
        section_name: "my_active",
      });
      this.myActiveCreators = data[0];
    },
  },
  channels: {
    InvoiceHmChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
};
</script>

<style lang="scss">
.hm-dashboard {
  &__go-to {
    background: white;
    border: 2px solid #262729;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0 0 66px 0;
    .page-title {
      margin: 15px 15px 15px 35px;
    }
    a {
      display: flex;
      background: #e12d1b;
      border-radius: 0 8px 8px 0;
      flex-basis: 258px;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 960px) {
  .hm-dashboard {
    &__go-to {
      margin-bottom: 48px;
      .page-title {
        margin: 15px;
      }
    }
  }
}
</style>
