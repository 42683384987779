<template>
  <tr class="payments-row">
    <td class="center-align">
      {{
        types[item.agreement_type] +
        (["msa_contractor", "msa_crew"].includes(item.agreement_type)
          ? ", " + item.msa_year
          : "")
      }}
    </td>
    <td class="center-align">
      {{
        item.gigs && item.gigs.length
          ? item.gigs.map((gig) => gig.title).join(", ")
          : "N/A"
      }}
    </td>
    <td
      class="agreement__status center-align"
      :class="`agreement__status--${item.status}`"
    >
      <span>{{ statuses[item.status] }}</span>
      <span v-if="item.email_viewed_at || item.documents_viewed_at">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18">$eye</v-icon>
          </template>
          <div>
            <div v-if="item.documents_viewed_at">
              Documents viewed at
              {{ item.documents_viewed_at | moment("hh:mm, dddd M/D/YYYY") }}
            </div>
            <div v-else-if="item.email_viewed_at">
              Email viewed at
              {{ item.email_viewed_at | moment("hh:mm, dddd M/D/YYYY") }}
            </div>
          </div>
        </v-tooltip>
      </span>
      <span v-if="item.name_changed_at">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="15">$pencil</v-icon>
          </template>
          <div>
            Name Changed
            {{ item.name_changed_to ? "to " + item.name_changed_to : "" }}
          </div>
        </v-tooltip>
      </span>
    </td>
    <td class="agreement__signed_at center-align">
      {{ signedAtFormatted(item) }}
    </td>
    <td>
      <TableRowAvatar :user="item.requested_by" />
    </td>
    <td class="agreement__requested_at center-align">
      {{ item.created_at | moment("timezone", currentTimezone, "MMM D, YYYY") }}
    </td>
    <td class="center-align">
      <ActionsMenu
        class="payments-row__actions-toggle"
        @close="menu = false"
        @toggle="menu = !menu"
        :menu="menu"
        :themeDisabledPage="themeDisabledPage"
        v-if="canDoAnyAction"
      >
        <v-btn
          v-if="canSendForSigning"
          @click="sendForSigning(item)"
          color="primary"
          dark
          elevation="0"
        >
          Send for Signing
        </v-btn>
        <div v-if="canSendReminder" class="btn-wrapper">
          <v-btn
            @click="sendReminderConfirm = true"
            color="primary"
            dark
            elevation="0"
          >
            Send Reminder
          </v-btn>
          <div class="agreement__reminder-notice" v-if="item.reminder_sent_at">
            Reminder sent at<br />
            {{
              item.reminder_sent_at
                | moment("timezone", currentTimezone, "MMMM Do YYYY, h:mma")
            }}
          </div>
        </div>
        <v-btn
          v-if="canDownloadPdf"
          @click="downloadPdf(item)"
          color="#262729"
          dark
          elevation="0"
        >
          Download PDF
        </v-btn>
        <v-btn
          v-if="canDownloadSignedUpload"
          @click="downloadPdf(item)"
          color="#262729"
          dark
          elevation="0"
        >
          Download
        </v-btn>
        <v-btn
          v-if="canReplaceAgreementUpload"
          @click="reuploadDialog = true"
          color="#262729"
          dark
          elevation="0"
        >
          Replace Upload
        </v-btn>
        <v-btn
          v-if="canCancel"
          @click="cancelConfirm = true"
          color="#262729"
          dark
          elevation="0"
        >
          Cancel
        </v-btn>
      </ActionsMenu>
      <AreYouSureDialog
        :dialog="cancelConfirm"
        @yes="cancelAgreement(item)"
        @no="cancelConfirm = false"
      >
        <p>Are you sure you want to cancel this agreement?</p>
      </AreYouSureDialog>
      <AreYouSureDialog
        :dialog="sendReminderConfirm"
        @yes="sendReminder(item)"
        @no="sendReminderConfirm = false"
      >
        <p>
          Are you sure you want to email<br /><span class="payments-row__em">{{
            item.user.full_name
          }}</span
          ><br />a reminder to sign this agreement?
        </p>
        <p
          class="payments-row__dialog-detail"
          v-if="['msa_contractor', 'msa_crew'].includes(item.agreement_type)"
        >
          {{ types[item.agreement_type] + ", " + item.msa_year }}
        </p>
        <p
          class="payments-row__dialog-detail"
          v-if="item.agreement_type == 'w9'"
        >
          W-9
        </p>
        <p
          class="payments-row__dialog-detail"
          v-if="item.agreement_type == 'ach_authorization'"
        >
          ACH Authorization
        </p>
        <p
          class="payments-row__dialog-detail"
          v-if="item.agreement_type == 'gig_contract'"
        >
          <span class="payments-row__dialog-detail__label"
            >Gig{{ item.gigs.length > 1 ? "s" : "" }}:
          </span>
          {{ gigList }}
        </p>
      </AreYouSureDialog>
      <ReplaceAgreementUploadDialog
        :dialog="reuploadDialog"
        :item="item"
        @refresh="$emit('refresh')"
        @close="reuploadDialog = false"
        :key="`ReplaceAgreementUploadDialog-${reloadKey}`"
      />
      <PleaseWaitDialog :dialog="pleaseWait" />
    </td>
  </tr>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import peopleTeamActions from "@/mixins/agreements/people-team-actions.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import peopleTeamAgreementsTableRow from "@/mixins/agreements/people-team-agreements-table-row.mixin";
import agreementRowActionsMixin from "@/mixins/agreements/agreement-row-actions.mixin";
import ReplaceAgreementUploadDialog from "@/pages/people-team/ReplaceAgreementUploadDialog";

export default {
  mixins: [
    themeDisabledPageMixin,
    paymentsTableFormats,
    peopleTeamActions,
    peopleTeamAgreementsTableRow,
    agreementRowActionsMixin,
  ],
  components: { TableRowAvatar, ReplaceAgreementUploadDialog },
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
@import "@/styles/agreements/payments-row.scss";
</style>
