import {
  ifAuthenticated,
  ifNotAuthenticated,
  ifReviewer,
  ifHiringManager,
  ifPeopleTeam,
  ifFinanceOrHiringManagerOrPeopleTeam,
  ifFinanceOrPeopleTeam,
} from "./auth";

import LoginPage from "@/pages/auth/login.page";
import ProfilePage from "@/pages/profile.page";
import ProfileViewPage from "@/pages/profile-view.page";
import CreatorsPage from "@/pages/creators.page";
import WildcardPage from "@/pages/_.page";

import MySubmissionsPage from "@/pages/submissions.page";
import SubmissionPage from "@/pages/submission.page";
import DashboardPage from "@/pages/dashboard.page";
import CommunityOpportunitiesPage from "@/pages/opportunities.page";
import MyPaymentsPage from "@/pages/my-payments.page";
import MyAgreementsPage from "@/pages/my-agreements.page";
import MyGigsPage from "@/pages/my-gigs.page";
import ShowMyGigPage from "@/pages/my-gig.page";
import ShowMyGigExtensionPage from "@/pages/my-gig-extension.page";
import PaymentsPage from "@/pages/hm/payments.page";
import CreatorPaymentsViewPage from "@/pages/creator-payments.page";
import CreatorAgreementsViewPage from "@/pages/creator-agreements.page";
import CreatorHireRequestsViewPage from "@/pages/creator-hire-requests.page";
import PaymentsAllGigsViewPage from "@/pages/hm/payments-all-gigs.page";
import PaymentsDashboardPage from "@/pages/fin/payments-dashboard.page";
import PaymentsReportsPage from "@/pages/fin/payments-reports.page";
import PaymentsAllProjectsViewPage from "@/pages/fin/all-projects.page";
import ProjectMembersPage from "@/pages/project-members.page";
import ShowGigPage from "@/pages/gigs/show.gigs.page";

import ForReviewPage from "@/pages/for-review.page";
import ReferralPage from "@/pages/referral.page";

import DownloadPage from "@/pages/download.page";

import ClaimAccountPage from "@/pages/claim-account.page";
import CreateOpportunityPage from "@/pages/create-opportunity.page";

import AgreementsPage from "@/pages/agreements.page";
import HireRequestsPage from "@/pages/hire-requests.page";
import HireRequestPage from "@/pages/hire-requests/show.hire-requests.page";
import MondayOnboardingPage from "@/pages/people-team/monday-onboarding.page";
import EditHireRequestPage from "@/pages/people-team/monday-onboarding-edit.page";
import GigExtensionPage from "@/pages/hire-requests/gig-extension.page";
import MondayOauthPage from "@/pages/people-team/monday-oauth.page";

const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: "Account",
    path: "/account",
    beforeEnter: async (to, from, next) => {
      return next({ name: "Profile" });
    },
  },
  {
    name: "Referral",
    path: "/referral",
    component: ReferralPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "CreateOpportunity",
    path: "/create-opportunity",
    component: CreateOpportunityPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Profile",
    path: "/profile",
    component: ProfilePage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "ProfileView",
    path: "/profile/:id",
    component: ProfileViewPage,
  },
  {
    name: "ProfileView",
    path: "/p/:id",
    component: ProfileViewPage,
  },
  {
    name: "CreatorPaymentsView",
    path: "/profile/:id/payments",
    component: CreatorPaymentsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "CreatorPaymentsView",
    path: "/p/:id/payments",
    component: CreatorPaymentsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Creator Agreements Page",
    path: "/profile/:id/agreements",
    component: CreatorAgreementsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Creator Agreements Page",
    path: "/p/:id/agreements",
    component: CreatorAgreementsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Creator Hire Requests Page",
    path: "/profile/:id/hire-requests",
    component: CreatorHireRequestsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Creator Hire Requests Page",
    path: "/p/:id/hire-requests",
    component: CreatorHireRequestsViewPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Gig Extension Page",
    path: "/gig-extension/:id",
    component: GigExtensionPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "My Opportunities",
    path: "/my-opportunities",
    beforeEnter: async (to, from, next) => {
      return next({ name: "Submissions" });
    },
  },
  {
    name: "Submissions",
    path: "/submissions",
    component: MySubmissionsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Dashboard",
    path: "/",
    component: DashboardPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "My Payments",
    path: "/my-payments",
    component: MyPaymentsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "My Agreements",
    path: "/my-agreements",
    component: MyAgreementsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "My Gigs",
    path: "/my-gigs",
    component: MyGigsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Payments",
    path: "/payments",
    component: PaymentsPage,
    beforeEnter: ifHiringManager,
  },
  {
    name: "Payments All Gigs",
    path: "/payments/all-gigs",
    component: PaymentsAllGigsViewPage,
    beforeEnter: ifHiringManager,
  },
  {
    name: "Payments Dashboard",
    path: "/payments-dashboard",
    component: PaymentsDashboardPage,
    beforeEnter: ifFinanceOrPeopleTeam,
  },
  {
    name: "Payments Reports",
    path: "/payments-reports",
    component: PaymentsReportsPage,
    beforeEnter: ifFinanceOrPeopleTeam,
  },
  {
    name: "Finance All Projects",
    path: "/finance/all-projects",
    component: PaymentsAllProjectsViewPage,
    beforeEnter: ifFinanceOrPeopleTeam,
  },
  {
    name: "Project Members",
    path: "/project/:id/members",
    component: ProjectMembersPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "ForReview",
    path: "/for-review",
    component: ForReviewPage,
    beforeEnter: ifReviewer,
  },
  {
    name: "Submission",
    path: "/submission/:id",
    component: SubmissionPage,
    beforeEnter: ifReviewer,
  },
  {
    name: "Creators",
    path: "/creators",
    component: CreatorsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "CreatorsName",
    path: "/creators/:name",
    component: CreatorsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Opportunities",
    path: "/opportunities",
    component: CommunityOpportunitiesPage,
  },
  {
    name: "ClaimAccount",
    path: "/claim-account",
    component: ClaimAccountPage,
  },
  {
    name: "Show Gig Page",
    path: "/gig/:id",
    component: ShowGigPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Show My Gig Page",
    path: "/my-gig/:id",
    component: ShowMyGigPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Show My Gig Extension Page",
    path: "/my-gig-extension/:id",
    component: ShowMyGigExtensionPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Agreements",
    path: "/agreements",
    component: AgreementsPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Download",
    path: "/download",
    component: DownloadPage,
    beforeEnter: ifAuthenticated,
  },
  {
    name: "Monday.com Onboarding",
    path: "/monday-onboarding/:id",
    component: MondayOnboardingPage,
    beforeEnter: ifPeopleTeam,
  },
  {
    name: "Edit Hire Request",
    path: "/monday-onboarding/:id/edit",
    component: EditHireRequestPage,
    beforeEnter: ifPeopleTeam,
  },
  {
    name: "Hire Request",
    path: "/hire-requests/:id",
    component: HireRequestPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Hire Requests",
    path: "/hire-requests",
    component: HireRequestsPage,
    beforeEnter: ifFinanceOrHiringManagerOrPeopleTeam,
  },
  {
    name: "Monday.com OAuth",
    path: "/monday-oauth",
    component: MondayOauthPage,
    beforeEnter: ifPeopleTeam,
  },
  {
    name: "WildcardPage",
    path: "/*",
    component: WildcardPage,
  },
];
export default routes;
