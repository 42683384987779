<template>
  <div class="referral-page">
    <div class="page-title">Referral Program</div>
    <p class="referral-page__intro">
      You can have up to {{ max }} referral link{{ max == 1 ? "" : "s" }} at a
      time. Give them to people you think would be a good addition to the
      community and if they use your claim link to create a verified Silver
      profile or higher, the two of you will get a reward!
    </p>
    <div class="referrals">
      <div class="referral" v-for="referral in referrals" :key="referral.id">
        <span v-if="referral.account_claimed_at"
          ><b>{{ referral.full_name }}</b> claimed their profile on
          <u>{{
            referral.account_claimed_at
              | moment("timezone", tz, "MM/DD/YY h:mm A zz")
          }}</u></span
        >
        <span v-else>
          <span>
            Invite sent to <b>{{ referral.full_name }}</b> ({{
              referral.email || referral.alt_contact
            }})
          </span>
        </span>
      </div>
      <v-form
        v-if="isHm"
        @submit.prevent="inviteUser"
        ref="form"
        class="invite-user"
      >
        <TextField placeholder="First Name" v-model="form.first_name" />
        <TextField placeholder="Last Name" v-model="form.last_name" />
        <TextField
          :rules="[validation.email]"
          placeholder="Email"
          v-model="form.email"
        />
        <v-btn :disabled="inProgress" type="submit">Send Invite</v-btn>
      </v-form>
      <br />
      <div class="referral-page__actions" v-if="false">
        <v-btn
          class="elevation-0"
          color="primary"
          large
          :href="referralsUrl"
          target="_blank"
          >Send Referral Link</v-btn
        >
        <v-btn
          class="elevation-0"
          large
          @click.prevent="supportDialog = true"
          v-if="this.supportForm"
          >Request More Referrals</v-btn
        >
      </div>
    </div>

    <v-dialog width="800" v-model="supportDialog" light>
      <v-card class="support__dialog">
        <v-btn class="dialog-close" icon @click="supportDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <SupportForm :support-form="supportForm" :hide-contact-fields="true" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { profileToFrontend } from "../lib/domains";
import moment from "moment";
export default {
  head: {
    title: "Account",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      form: {},
      referrals: null,
      tz: moment.tz.guess(true),
      referralsUrl:
        profileToFrontend()[window.location.origin] + "/core-referrals",
      supportDialog: false,
      supportForm: null,
      inProgress: false,
    };
  },
  created() {
    this.setReferrals();
    this.fetchSupportForm();
  },
  computed: {
    isHm() {
      return this.currentUser.roles.find((t) => t.name == "hiring_manager");
    },
    max() {
      return this.currentUser.badges.filter((t) => t.slug == "referrer").length;
    },
  },
  methods: {
    async inviteUser() {
      this.inProgress = true;
      const invalid = this.$refs.form && !this.$refs.form.validate();

      if (invalid) {
        this.scrollToInvalidInput(this.$refs.form.inputs);
        this.inProgress = false;
        return;
      }
      if (!this.form.email || !this.form.first_name) {
        this.inProgress = false;
        return;
      }

      try {
        await this.$api.Referral.invite(this.form);
        this.setReferrals();
        this.form = {};
        this.$alert("Invite has been sent!");
      } catch (e) {
        this.$alert(e.response.data.errors[0], "error");
      } finally {
        this.inProgress = false;
      }
    },
    async setReferrals() {
      const { data } = await this.$api.Referral.get();
      this.referrals = data;
    },
    async createReferral() {
      await this.$api.Referral.create();
      this.setReferrals();
      this.$alert("Referral link has been successfully created");
    },
    async fetchSupportForm() {
      const { data } = await this.$api.Support.getRequestReferralsForm();
      this.supportForm = data;
    },
  },
  watch: {
    supportDialog: function (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$root.$emit("requestReferralsPopulate");
        });
      } else {
        this.$root.$emit("resetSupportForm", "request_referrals");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.referral-page {
  &__intro {
    font-size: 20px;
    line-height: 1.2;
  }
  &__actions {
    > *:not(:last-child) {
      margin-right: 29px;
    }
  }
}

.invite-user {
  display: flex;
  max-width: 800px;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
  border: 2px solid black;
  border-radius: 10px;

  &::v-deep {
    .text-field {
      margin-right: 15px;
    }
  }
}
.referrals {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.referral {
  padding: 8px 15px;
  background: white;
  border: 2px solid black;
  margin-bottom: 13px;
  border-radius: 10px;
  font-size: 20px;
}
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.referral-page__actions .v-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
