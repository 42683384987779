<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0 center-align">Invoice</th>
            <th>
              <div>Date Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
            <th class="w0 center-align">Amount</th>
            <th class="w0 center-align">
              <div>Date Paid</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="paid_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Paid"
                isDate
              />
            </th>
            <th>
              <div>Project(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Creator</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="user"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="status"
                fieldLabel="Status"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0 center-align">
              <div>Actions</div>
              <div class="th-select-holder">
                <Checkbox
                  class="th-select__checkbox"
                  @input="batchSelectAll"
                  :value="allBatchSelected"
                />
                <ActionsMenu
                  v-if="currentUser.is_finance || currentUser.is_people_team"
                  @close="batchMenu = false"
                  @toggle="batchMenu = !batchMenu"
                  :menu="batchMenu"
                  themeDisabledPage
                  :disabled="batchSelected.length == 0"
                >
                  <v-btn
                    @click="batchMarkAsUnpaidAreYouSure = true"
                    color="#7B61FF"
                    elevation="0"
                    dark
                  >
                    Mark as Unpaid
                  </v-btn>
                  <v-btn
                    @click="batchCancelAreYouSure = true"
                    color="primary"
                    elevation="0"
                    dark
                  >
                    Cancel
                  </v-btn>
                </ActionsMenu>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <PaymentsDashboardCompletedTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            @openDialog="
              activeInvoiceId = item.id;
              dialog = true;
            "
            @refresh="$emit('refresh')"
            @batchSelect="batchSelect(item, $event)"
            :selected="batchSelected.includes(item.id)"
            @openAuditDialog="
              auditDialog = true;
              activeInvoiceId = item.id;
            "
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="center-align">No payments</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn
          class="dialog-close"
          icon
          @click="
            dialog = false;
            activeInvoiceId = null;
          "
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <InternalInvoice
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`fiinvoice-${activeInvoiceId}`"
        />
      </v-card>
    </v-dialog>
    <AreYouSureDialog
      :dialog="batchMarkAsUnpaidAreYouSure"
      @yes="batchMarkAsUnpaid"
      @no="batchMarkAsUnpaidAreYouSure = false"
    >
      <p>
        This will mark the {{ batchSelected.length }} selected invoices as
        unpaid.
      </p>
      <p><b>Are you sure you want to mark these invoices as unpaid?</b></p>
    </AreYouSureDialog>
    <AreYouSureDialog
      :dialog="batchCancelAreYouSure"
      @yes="batchMarkAsCanceled"
      @no="batchCancelAreYouSure = false"
    >
      <p>
        This will remove the {{ batchSelected.length }} selected invoices from
        payments lists on Community.
      </p>
      <p><b>Are you sure you want to mark these invoices as canceled?</b></p>
    </AreYouSureDialog>
    <v-dialog max-width="800" v-model="auditDialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="auditDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <InvoiceAuditTrail
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`hminvoice-${activeInvoiceId}`"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InternalInvoice from "@/components/work-assignments/InternalInvoice";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import PaymentsDashboardCompletedTableRow from "./completed-table-row.payments-dashboard.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";
import fiBatchActionsMixin from "@/mixins/invoices/fi-batch-actions.mixin";
import InvoiceAuditTrail from "@/components/work-assignments/InvoiceAuditTrail";

export default {
  mixins: [paymentsTableFormats, fiBatchActionsMixin],
  components: {
    InternalInvoice,
    PaymentsDashboardCompletedTableRow,
    TableHeaderActions,
    InvoiceAuditTrail,
  },
  props: {
    title: String,
    items: Array,
    listOptions: Object,
  },
  data() {
    return {
      dialog: false,
      activeInvoiceId: null,
      auditDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-head {
    background: #7b61ff;
    color: white;
  }
}
</style>
