var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.creator)?_c('div',{staticClass:"agreements-page"},[_c('div',{staticClass:"agreements-page__header"},[_c('a',{staticClass:"back-to-agreements",on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v("$arrow_left")])],1),(_vm.currentUser.is_people_team)?_c('div',{staticClass:"agreements-page__actions"},[_c('v-btn',{staticClass:"agreements-page__action-btn agreements-page__sa-btn",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.sendAgreementDialog = true}}},[_vm._v(" Send Agreement ")]),_c('v-btn',{staticClass:"agreements-page__action-btn agreements-page__sa-btn",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.uploadAgreementDialog = true}}},[_vm._v(" Upload Signed Agreement ")])],1):_vm._e()]),_c('CreatorAgreementsTable',{class:{ 'table-loading': _vm.loadingList },attrs:{"items":_vm.list,"title":((_vm.creator.full_name) + "’s Agreements"),"listOptions":_vm.listOptions},on:{"refresh":_vm.refreshAll,"orderUpdate":function($event){return _vm.orderUpdate($event)},"filterUpdate":function($event){return _vm.filterUpdate($event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"payments-table__action"},[_c('div',{staticClass:"payments-table__action__label"},[_vm._v("Filter by:")]),_c('v-overlay',{attrs:{"value":_vm.filtersMenu},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeFilters()}}}),_c('v-menu',{staticClass:"filters-menu",attrs:{"bottom":"","offset-y":"","close-on-click":false,"close-on-content-click":false,"light":"","content-class":"filters-menu__content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('TableActiveFilters',_vm._g({attrs:{"listOptions":_vm.listOptions,"fields":_vm.filterFields,"activeListOption":_vm.activeListOption},on:{"openMenu":function($event){_vm.filtersMenu = true},"resetFilter":function($event){return _vm.resetFilter($event)}}},on))]}}],null,false,3704698761),model:{value:(_vm.filtersMenu),callback:function ($$v) {_vm.filtersMenu=$$v},expression:"filtersMenu"}},[_c('v-card',{staticClass:"filters-menu__content__card"},[_c('div',{staticClass:"filters-menu__body"},[_c('v-expansion-panels',{attrs:{"multiple":"","flat":""},model:{value:(_vm.filterPanels),callback:function ($$v) {_vm.filterPanels=$$v},expression:"filterPanels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Project ")]),_c('v-expansion-panel-content',[_c('InternalProjectsSelector',{attrs:{"emptyLabel":"All","params":{
                        agreements: true,
                        user_id: _vm.$route.params.id,
                      }},on:{"input":function($event){_vm.optionsUpdate();
                        _vm.closeFilters();}},model:{value:(_vm.listOptions.internal_project_id),callback:function ($$v) {_vm.$set(_vm.listOptions, "internal_project_id", $$v)},expression:"listOptions.internal_project_id"}})],1)],1),_c('TableOptionsFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"agreement_type","fieldLabel":"Type","selectAll":"","reset":"","defaultListOptions":_vm.getDefaultListOptions()},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('TableOptionsFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"status","fieldLabel":"Status","selectAll":"","reset":"","defaultListOptions":_vm.getDefaultListOptions()},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('TableDateFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"created_at","fieldLabel":"Date Signed"},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('TableDateFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"created_at","fieldLabel":"Date Requested"},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Role ")]),_c('v-expansion-panel-content',[_c('SkillsSelector',{attrs:{"placeholder":"Role"},on:{"input":function($event){_vm.optionsUpdate();
                        _vm.closeFilters();}},model:{value:(_vm.listOptions.skill_id),callback:function ($$v) {_vm.$set(_vm.listOptions, "skill_id", $$v)},expression:"listOptions.skill_id"}})],1)],1)],1)],1)])],1)],1),_c('div',{staticClass:"payments-table__action"},[_c('div',{staticClass:"payments-table__action__label"}),_c('TextField',{attrs:{"emptyLabel":"All","params":{ agreements: true },"placeholder":"Search"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{staticClass:"payments-table__secondary-actions"},[(_vm.anyActiveListOptions)?_c('div',{staticClass:"payments-table__action-btn payments-table__action-btn--reset"},[_c('div',{on:{"click":function($event){return _vm.resetListOptions()}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("$close")]),_vm._v(" Reset ")],1)]):_vm._e()])]},proxy:true},{key:"pagination",fn:function(){return [_c('TablePagination',{key:("pagination-" + _vm.reloadKey),attrs:{"page":_vm.page,"totalPages":_vm.totalPages},on:{"input":function($event){_vm.page = $event}}})]},proxy:true}],null,false,3182931311)}),_c('SendAgreementDialog',{key:("SendAgreementDialog-" + _vm.reloadKey),attrs:{"dialog":_vm.sendAgreementDialog,"user":_vm.creator},on:{"refresh":_vm.refreshAll,"close":function($event){_vm.sendAgreementDialog = false}}}),_c('UploadAgreementDialog',{key:("UploadAgreementDialog-" + _vm.reloadKey),attrs:{"dialog":_vm.uploadAgreementDialog,"user":_vm.creator},on:{"refresh":_vm.refreshAll,"close":function($event){_vm.uploadAgreementDialog = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }