<template>
  <div class="for-review-page" v-if="currentUser">
    <div class="page-title">For Review</div>
    <div class="page-subtitle">
      Below are active Creators, Gigs, Ideations, and Submissions for your
      review.
    </div>
    <div v-if="creatorsForReview" class="for-review__creators">
      <CreatorsSection :preview="true" :value="creatorsForReview" light />
    </div>
    <div v-if="loaded">
      <SubmissionReviewGroup :value="gigGroups" title="Gigs for Review" />
      <SubmissionReviewGroup
        :value="otherGroups"
        title="Submissions for Review"
      />
    </div>
    <div class="no-entries" v-else-if="noSubmissions">No Submissions</div>
    <Spinner v-else />
  </div>
</template>

<script>
import IndexPage from "./index-page";
import SubmissionReviewGroup from "@/components/review/SubmissionReviewGroup.vue";

export default {
  name: "ForReviewPage",
  extends: IndexPage,
  components: { SubmissionReviewGroup },
  head: {
    title: "For Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      gigGroups: null,
      otherGroups: null,
      submissionDialog: false,
      submissionDialogContent: null,
      creatorsForReview: null,
      creatorsForReviewInc: 0,
    };
  },
  mounted() {
    this.fetchCreatorsForReview();
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.Submission.forReview();

      this.gigGroups = data.gig_groups;
      this.otherGroups = data.other_groups;
    },
    showSubmission(submission) {
      this.submissionDialogContent = submission;
      this.submissionDialog = true;
    },
    async fetchCreatorsForReview() {
      const { data } = await this.$api.Creator.sections({
        section_name: "for_review",
      });
      this.creatorsForReview = data[0];
    },
  },
  computed: {
    loaded() {
      return (
        (this.gigGroups && this.gigGroups.length > 0) ||
        (this.otherGroups && this.otherGroups.length > 0)
      );
    },
    noSubmissions() {
      return (
        this.gigGroups &&
        this.gigGroups.length == 0 &&
        this.otherGroups &&
        this.otherGroups.length == 0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.for-review {
  &__creators {
    margin-top: 77px;
  }
}
</style>
