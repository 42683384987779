<template>
  <div class="mp-header__personal">
    <a class="mp-header__avatar" :href="`/p/${user.username}`" target="_blank">
      <img :src="avatar" :alt="`${user.full_name}’s profile picture`" />
    </a>
    <div class="mp-header__info">
      <a
        class="mp-header__fullname"
        :href="`/p/${user.username}`"
        target="_blank"
        >{{ user.full_name }}</a
      >
      <div class="mp-header__extra" v-if="user.contact_address">
        <span v-if="user.contact_address.street"
          >{{ user.contact_address.street }} <br
        /></span>
        <span v-if="user.contact_address.location || user.contact_address.zip"
          >{{ `${user.contact_address.location} ${user.contact_address.zip}` }}
          <br
        /></span>
      </div>
      <div class="mp-header__extra" v-if="user.phone || user.contact_email">
        <span v-if="user.phone">{{ parsePhoneNumber(user.phone) }} </span
        ><span
          class="mp-header__extra__separator"
          v-if="user.phone && user.contact_email"
          >| </span
        ><span v-if="user.phone">{{ user.contact_email }}</span>
      </div>
      <div class="mp-header__extra" v-else>
        <span v-if="user.primary_working_location"
          >{{ user.primary_working_location }} <br
        /></span>
        <span v-if="user.phone || user.contact_email">
          {{ user.phone }} | {{ user.contact_email }}
        </span>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

export default {
  props: {
    user: Object,
  },
  computed: {
    avatar() {
      return (
        this.user.avatar.url ||
        "https://commons-saas.nyc3.digitaloceanspaces.com/default_avatar.svg"
      );
    },
  },
  methods: {
    parsePhoneNumber(number) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedNumber = phoneUtil.parse(number, "US");

        // Check if the number is from the US
        if (parsedNumber.getCountryCode() === 1) {
          const nationalNumber = phoneUtil.format(
            parsedNumber,
            PhoneNumberFormat.NATIONAL
          );
          if (number.startsWith("+1")) {
            return ` +1 ${nationalNumber}`;
          }
          return nationalNumber;
        }

        // For non-US numbers, reutrn the international format
        return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      } catch (error) {
        console.info(error);
        return number;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mp-header {
  &__personal {
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  &__avatar {
    width: 156px;
    height: 156px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    align-self: flex-start;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &__info {
    margin-left: 20px;
    @media screen and (max-width: 600px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &__fullname {
    font-family: "Source Sans Pro";
    font-weight: 900;
    font-size: 20px;
    color: #262729;
    text-decoration: none;
  }
}

@media screen and (max-width: 600px) {
  .mp-header {
    &__info {
      align-self: flex-start;
    }
    &__extra {
      span {
        display: block;
      }
      &__separator {
        display: none !important;
      }
    }
  }
}
</style>
