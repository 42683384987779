<template>
  <tr class="payments-row">
    <td class="center-align">{{ `${item.code} - ${item.title}` }}</td>
    <td v-if="showCreators" class="center-align">
      <div class="payments-row__creators">
        <v-btn
          v-for="creator in item.creators"
          :key="creator.id"
          x-small
          elevation="0"
          class="payments-row__creator"
          color="#E12D1B"
          :to="`/p/${creator.username || creator.id}/payments`"
        >
          {{ creator.full_name }}
        </v-btn>
      </div>
    </td>
    <td>
      <div class="td-action">
        <v-btn icon :to="`/project/${item.id}/members`">
          <v-icon size="35">$arrow_right</v-icon>
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  props: {
    item: Object,
    showCreators: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.payments-row {
  &__creators {
    text-align: center;
  }
  &__creator {
    border-radius: 7px !important;
    margin: 0.25em 0.5em;
  }
}
</style>
