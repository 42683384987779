<template>
  <div class="ob__alerts" v-if="onboardingDetails">
    <div
      class="ob__alert ob__alert--error"
      v-if="
        onboardingDetails.msa_status == 'NONE' ||
        onboardingDetails.w9_status == 'NONE' ||
        onboardingDetails.ach_status == 'NONE' ||
        onboardingDetails.gig_contract_needed
      "
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <img
            v-on="on"
            src="@/assets/onboarding-alerts/needs-signature.svg"
            class="ob__icon"
            alt="Signature needed alert."
          />
        </template>
        <div>
          <div v-if="onboardingDetails.msa_status == 'NONE'">
            Creator has not signed a current-year MSA.
          </div>
          <div v-if="onboardingDetails.w9_status == 'NONE'">
            Creator has not filled out a W-9.
          </div>
          <div v-if="onboardingDetails.ach_status == 'NONE'">
            Creator has not filled out an ACH authorization.
          </div>
          <div v-if="onboardingDetails.gig_contract_needed">
            Creator has gig(s) that need a contract.
          </div>
        </div>
      </v-tooltip>
    </div>
    <div
      class="ob__alert ob__alert--error"
      v-if="!onboardingDetails.qb_is_linked"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <img
            v-on="on"
            src="@/assets/onboarding-alerts/no-qbo-vendor.svg"
            class="ob__icon"
            alt="Broken link alert"
          />
        </template>
        <div>Creator is not linked to a QBO vendor.</div>
      </v-tooltip>
    </div>
    <div
      class="ob__alert ob__alert--msg"
      v-if="
        onboardingDetails.msa_status == 'OUT_FOR_SIGNATURE' ||
        onboardingDetails.w9_status == 'OUT_FOR_SIGNATURE' ||
        onboardingDetails.ach_status == 'OUT_FOR_SIGNATURE' ||
        onboardingDetails.gig_contract_signature_needed ||
        onboardingDetails.gig_extension_signature_needed
      "
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <img
            v-on="on"
            src="@/assets/onboarding-alerts/out-for-signature.svg"
            class="ob__icon"
            alt="Out for signature alert"
          />
        </template>
        <div>
          <div v-if="onboardingDetails.msa_status == 'OUT_FOR_SIGNATURE'">
            MSA out for signature.
          </div>
          <div v-if="onboardingDetails.w9_status == 'OUT_FOR_SIGNATURE'">
            W-9 out for signature.
          </div>
          <div v-if="onboardingDetails.ach_status == 'OUT_FOR_SIGNATURE'">
            ACH authorization out for signature.
          </div>
          <div v-if="onboardingDetails.gig_contract_signature_needed">
            Creator has a gig contract that needs a signature.
          </div>
          <div v-if="onboardingDetails.gig_extension_signature_needed">
            Creator has a gig extension that needs a signature.
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onboardingDetails: Object,
  },
};
</script>

<style lang="scss" scoped>
.ob {
  &__alerts {
    display: flex;
  }
  &__alert {
    min-width: 18px;
    height: 18px;
    display: flex;
    &:not(:last-child) {
      margin-right: 2px;
    }
  }
  &__icon {
    width: 18px;
    height: 18px;
  }
}
</style>
