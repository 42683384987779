<template>
  <v-component
    :is="path && newTab ? 'a' : path ? 'router-link' : 'div'"
    :to="path"
    :href="path"
    class="avatar-link"
    :target="newTab ? '_blank' : null"
  >
    <div class="avatar-wrapper">
      <div class="avatar" :style="avatarStyles"></div>
    </div>
    <div class="avatar__name">
      {{ user.full_name }}
    </div>
    <CreatorOnboardingAlerts
      :onboardingDetails="user.onboarding_details"
      class="avatar__alerts"
    />
  </v-component>
</template>

<script>
import CreatorOnboardingAlerts from "@/components/work-assignments/CreatorOnboardingAlerts";

export default {
  components: { CreatorOnboardingAlerts },
  props: {
    user: Object,
    path: String,
    newTab: Boolean,
  },
  computed: {
    showAvatar() {
      return this.user?.avatar?.thumb?.url;
    },
    avatarStyles() {
      return {
        backgroundImage: this.showAvatar
          ? `url(${this.user.avatar.thumb.url})`
          : `url("https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/75a8c299-edb9-41c1-9071-3101364c39af/default_user_avatar.svg")`,
      };
    },
  },
};
</script>

<style lang="scss">
td .avatar-link {
  width: fill-available;
  width: -webkit-fill-available;
}
</style>

<style lang="scss" scoped>
.avatar {
  margin-right: 10px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: gray;
  &-wrapper {
    position: relative;
    margin-top: 4px;
    align-self: flex-start;
  }
  &__name {
    flex-grow: 1;
  }
  &__alerts {
    top: -18px;
    left: -14px;
    margin-left: 1em;
  }
}

div.avatar-link,
a.avatar-link {
  color: #56565a;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  text-decoration: none;
}
</style>
