<template>
  <div class="agreements-page">
    <div class="agreements-page__header">
      <a class="back-btn" @click="$router.back()">
        <v-icon size="50">$arrow_left</v-icon>
      </a>
      <div
        class="agreements-page__actions"
        v-if="currentUser.is_people_team || currentUser.is_finance"
      >
        <v-btn
          color="primary"
          @click="sendAgreementDialog = true"
          elevation="0"
          class="agreements-page__sa-btn"
        >
          Send Agreement
        </v-btn>
        <v-btn
          color="primary"
          @click="uploadAgreementDialog = true"
          elevation="0"
          class="agreements-page__action-btn agreements-page__sa-btn"
        >
          Upload Signed Agreement
        </v-btn>
      </div>
    </div>
    <AgreementsTable
      :items="list"
      title="All Agreements"
      @refresh="refreshAll"
      :class="{ 'table-loading': loadingList }"
      :listOptions="listOptions"
      @orderUpdate="orderUpdate($event)"
      @filterUpdate="filterUpdate($event)"
      :reloadKey="reloadKey"
    >
      <template #actions>
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Creator
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <UsersSelector
                        v-model="listOptions.user_id"
                        placeholder="All"
                        :params="{ agreements: true }"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        includeBlocked
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Project
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InternalProjectsSelector
                        v-model="listOptions.internal_project_id"
                        emptyLabel="All"
                        :params="{ agreements: true }"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="agreement_type"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Type"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="status"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Status"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="signed_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Signed"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Requested"
                  />
                  <v-expansion-panel>
                    <v-expansion-panel-header> Role </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <SkillsSelector
                        v-model="listOptions.skill_id"
                        placeholder="Role"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="payments-table__action">
          <div class="payments-table__action__label"></div>
          <TextField
            v-model="searchInput"
            emptyLabel="All"
            :params="{ agreements: true }"
            placeholder="Search"
          />
        </div>
        <div class="payments-table__secondary-actions">
          <div
            class="payments-table__action-btn payments-table__action-btn--reset"
            v-if="anyActiveListOptions"
          >
            <div @click="resetListOptions()">
              <v-icon size="14">$close</v-icon> Reset
            </div>
          </div>
        </div>
      </template>
      <template #pagination>
        <TablePagination
          :page="page"
          :totalPages="totalPages"
          @input="page = $event"
          :key="`pagination-${reloadKey}`"
        />
      </template>
    </AgreementsTable>
    <SendAgreementDialog
      :dialog="sendAgreementDialog"
      @refresh="refreshAll"
      @close="sendAgreementDialog = false"
      :key="`SendAgreementDialog-${reloadKey}`"
    />
    <UploadAgreementDialog
      :dialog="uploadAgreementDialog"
      @refresh="refreshAll"
      @close="uploadAgreementDialog = false"
      :key="`UploadAgreementDialog-${reloadKey}`"
    />
  </div>
</template>

<script>
import IndexPage from "./index-page";
import AgreementsTable from "./agreements/table.agreements";
import SendAgreementDialog from "./people-team/SendAgreementDialog";
import UploadAgreementDialog from "./people-team/UploadAgreementDialog";
import agreementsTableActions from "@/mixins/agreements/agreements-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  name: "AgreementsPage",
  extends: IndexPage,
  mixins: [agreementsTableActions, paginatedTable],
  components: {
    AgreementsTable,
    SendAgreementDialog,
    UploadAgreementDialog,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
  },
  head: {
    title: "Agreements",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      myActiveCreators: null,
      list: [],
      loadingList: false,
      reloadKey: 1,
      sendAgreementDialog: false,
      uploadAgreementDialog: false,
      filterFields: [
        { name: "user_id", label: "Creator" },
        { name: "internal_project_id", label: "Project" },
        { name: "agreement_type", label: "Type" },
        { name: "status", label: "Status" },
        { name: "signed_at", label: "Date Signed" },
        { name: "created_at", label: "Date Requested" },
        { name: "skill_id", label: "Role" },
      ],
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "AgreementPeopleTeamChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  methods: {
    async refreshAll() {
      this.sendAgreementDialog = false;
      this.fetchList();
      this.reloadKey++;
    },
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = ["sm", "xs"].includes(this.$size) ? 5 : 10;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Agreement.list(params);
      this.list = data.agreements;
      console.info("list (data.agreements)", this.list);
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
    async handleAgreementBroadcast(type, payload) {
      if (
        !payload.transaction_id ||
        payload.transaction_id != this.$store.getters["agreement/transactionId"]
      ) {
        this.fetchList();
        this.reloadKey++;
      }
    },
  },
  channels: {
    AgreementPeopleTeamChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleAgreementBroadcast(type, payload);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/agreements/agreements-page.scss";
@import "@/styles/table-filters-menu.scss";

.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
}

.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
