<template>
  <div class="creators-search-wrapper">
    <div class="creators-search">
      <router-link to="/creators" class="creators-search__title page-title">
        <span>Creators</span>
        <img :src="underlineImg" alt=""/>
      </router-link>
      <div class="creators-search__search-wrapper">
        <div class="creators-search__search">
          <div class="creators-search__search-section">
            <v-text-field
              hide-details="auto"
              outlined
              dense
              v-model="localValue.query"
              prepend-inner-icon="$search"
              placeholder="Search"
              clearable
              class="creators-search__filter-input"
            ></v-text-field>
          </div>
          <div class="show-mobile-filters">
            <v-btn
              outlined
              small
              @click="filtersShowMobile = !filtersShowMobile"
              >{{ filtersShowMobile ? "Hide" : "Show" }} Filters</v-btn
            >
          </div>
          <div
            :class="
              filtersShowMobile
                ? 'visible-mobile-filters'
                : 'invisible-mobile-filters'
            "
            class="creators-search__search-section"
            v-show="filtersShow"
          >
            <div class="creators-search__filters">
              <div class="creators-search__filter">
                <SkillsSelector
                  label="Specialties"
                  multiple
                  :maximum="5"
                  v-model="localValue.skill_ids"
                  class="creators-search__filter-input"
                />
                <!-- <SkillsSelector
                  label="Secondary Specialty"
                  v-model="localValue.secondary_skill_id"
                  class="creators-search__filter-input"
                /> -->
              </div>
              <div class="creators-search__filter">
                <LocationSelector
                  v-model="localValue.location"
                  placeholder="Location"
                  source="place_name_en"
                  type="city"
                  dense
                  class="creators-search__filter-input"
                />
                <RadiusSelector
                  class="creators-search__filter-input"
                  style="max-width: 150px"
                  label="Radius (miles)"
                  :disabled="!localValue.location"
                  v-model="localValue.radius"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="creators-search__filters-toggle">
          <v-btn icon @click="filtersShow = !filtersShow">
            <v-icon color="#E12D1B">$filter</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      filtersShow: false,
      filtersShowMobile: false,
      localValue: { ...this.value },
      underlineImg: require("@/assets/creators-underline.svg"),
    };
  },
  methods: {
    setValue: _.debounce(function () {
      this.$emit("input", { ...this.localValue });
    }, 300),
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.setValue();
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue == { ...this.value };
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.creators-search {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  &__filters {
    width: 100%;
    display: flex;
    margin: 20px 0 0;
    @media screen and (max-width: 960px) {
      display: block;
      margin-bottom: 0;
    }
  }

  &__filter {
    position: relative;
    display: flex;
    min-width: 50%;
    // padding: 20px 10px;

    &-input {
      width: 100%;
      margin: 0 5px;
      &::v-deep fieldset {
        border: 2px solid var(--v-dark-base);
        border-radius: 10px;
      }
    }
    @media screen and (max-width: 960px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin: 0;
    text-decoration: none;
    color: transparent;
    position: relative;

    span {
      z-index: 1;
      position: relative;
    }

    img {
      position: absolute;
      left: -28px;
      bottom: -3px;

      @media screen and (max-width: 960px) {
        bottom: 2px;
        width: 220px;
      }
    }

    @media screen and (max-width: 960px) {
      margin-bottom: 25px;
    }
  }

  &__search {
    width: 100%;
    flex-grow: 1;
    &-wrapper {
      flex-grow: 1;
      display: flex;
      margin: 0 0 0 40px;
      @media screen and (max-width: 960px) {
        margin-left: 0;
        width: 100%;
      }
    }
    &-section {
      display: flex;
      @media screen and (max-width: 960px) {
        display: flex !important;
      }
    }

    &::v-deep {
      .v-input__icon .v-icon {
        color: #e12d1b;
      }
    }
  }
  &__filters-toggle {
    margin-left: 10px;
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
}

.show-mobile-filters {
  margin-top: 10px;
  display: none !important;
}
@media screen and (max-width: 960px) {
  .show-mobile-filters {
    display: block !important;
    margin-left: 5px;
  }
  .invisible-mobile-filters {
    display: none !important;
  }

  .visible-mobile-filters {
    display: block !important;
  }

  .creators-search__filter-input {
  }

  .creators-search__search {
    margin: 0 -5px;
  }

  .creators-search-wrapper {
    width: 100%;
  }
}
</style>
