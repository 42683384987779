<template>
  <TagSelector
    :multiple="multiple"
    :items="users"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    itemText="full_name"
    itemValue="id"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    roles: Array,
    badges: Array,
    appendIcon: String,
    placeholder: String,
    rules: Array,
    includeBlocked: Boolean,
  },
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.setUsers();
  },
  methods: {
    async setUsers() {
      const params = this.roles ? { roles: this.roles } : {};
      if (this.badges) params.badges = this.badges;
      if (this.includeBlocked) params.include_blocked = true;
      const { data } = await this.$api.User.lite(params);
      this.users = data;
    },
  },
};
</script>

<style></style>
