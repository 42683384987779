<template>
  <div class="creators-page">
    <div class="creators-page__search">
      <CreatorsSearch v-model="search" />
      <ThemeSwitch />
    </div>
    <div class="creators-page__sections" :key="$route.path">
      <CreatorsSections :search="search" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  head() {
    let meta = [...this.currentProject.meta_tags];

    meta = _.uniqBy(meta, function(tag) { return (tag.name || "") + "_" + (tag.property || "") });

    return {
      title: "Creators",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        { rel: "icon", href: this.currentProject?.favicon.url || this.activeTheme.favicon.url || "/favicon.ico" }
      ]
    };
  },
  data() {
    return {
      search: {
        query: "",
        location: "",
      },
    };
  },
};
</script>

<style scoped lang="scss">
.creators-page {
  &__search {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 70px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      margin-bottom: 39px;
    }

    &::v-deep {
      .theme-switch {
        @media screen and (max-width: 960px) {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.creators-page {
  .tag-selector.v-input {
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 40px !important;
    }
    &.v-text-field--outlined.v-input--dense .v-label {
      top: 10px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
      margin-top: 10px !important;
    }

    .v-label.theme--dark {
      color: var(--v-dark-base);
      opacity: 0.7;

      &.v-label--active {
        opacity: 1;
      }
    }

    &.theme--dark {
      fieldset {
        border-color: var(--v-dark-base) !important;
      }
    }
  }
  .creators-search-wrapper {
    flex: 1;
  }
}
</style>
