<template>
  <div class="submission-review-item">
    <div
      v-if="value && (value.youtube_id || value.vimeo_id)"
      class="submission-review-item__preview"
    >
      <v-responsive
        class="submission-review-item__video"
        :aspect-ratio="497 / 280"
        v-if="value.youtube_id || value.vimeo_id"
      >
        <img @click="$emit('showVideo', value)" :src="value.preview.large.url || vimeoPlaceholder" width="100%" />
      </v-responsive>
      <img v-else :src="value.preview.large.url" width="100%" />
    </div>
    <div
      class="submission-review-item__content"
      v-if="content.length"
    >
      <div class="submission-review-item__user">
        <div class="submission-review-item__user-name">{{ item.user.full_name }}</div>
        <div class="submission-review-item__user-email">
          <a :href="`mailto:${item.user.email}`" target="_blank">{{ item.user.email }}</a>
        </div>
      </div>
      <div
        class="submission-review-item__field"
        :class="'s-' + section.class_name"
        v-for="(section, idx) in content"
        :key="idx"
      >
        <div class="submission-review-item__field_label">
          {{ section.label }}
        </div>
        <div class="submission-review-item__field_value">
          {{ section.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    layout: Object
  },
  data() {
    return {
      videoDialog: false,
      vimeoPlaceholder: require("@/assets/vimeo-placeholder.png"),
    }
  },
  computed: {
    content() {
      if(!this.layout.content) return {};
      return this.layout.content
        .map(t => ({
          ...this.formattedData[t.field_id],
          class_name: t.uuid
        }))
        .filter(t => t);
    },
    formattedData() {
      const result = {};
      this.item.formatted_data.forEach(t => (result[t.uuid] = t));
      return result;
    },
    value() {
      const t = this.item.formatted_data.find(
        t => t.uuid == this.layout.preview
      );
      return t?.value;
    }
  }
};
</script>

<style scoped lang="scss">
.submission-review-item {
  &__preview {
    float: right;
    width: 50%;
    margin: 0 calc(25% - 30px) 30px 30px;
    @media screen and (max-width: 960px) {
      width: 100%;
      margin: 0 0 30px 0;
    }
  }
  &__content {
    color: #262729;
    padding-top: 3.35em;
  }
  &__preview {
    cursor: pointer;
  }
  &__field {
    margin-bottom: 1.43em;

    &_label {
      font-size: 14px;
      font-weight: 700;
    }
    &_value {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 300;
    }
  }

  &__user {
    color: #262729 !important;
    margin-bottom: 1.43em;
    &-name {
      font-weight: bold;
      font-size: 14px;
    }
    a {
      color: #262729 !important;
      text-decoration: none;
    }
    &-email {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 300;
    }
  }
}
</style>
