<template>
  <Invoice
    v-if="invoice"
    :invoice="invoice"
    @refresh="$emit('refresh')"
  >

    <template v-slot:actions>
      <div class="invoice-actions">
      </div>
    </template>

    <template v-slot:dialogs>
    </template>
  </Invoice>
</template>

<script>
import Invoice from "@/components/work-assignments/Invoice";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  components: {
    Invoice,
  },
  mixins: [paymentsTableFormats],
  props: {
    value: String
  },
  data() {
    return {
      invoice: null,
    }
  },
  created() {
    this.$cable.subscribe({
      channel: "InvoiceCreatorChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    this.fetchInvoice();
  },
  methods: {
    async fetchInvoice() {
      const { data } = await this.$api.Invoice.get({ id: this.value });
      this.invoice = data;
    },
    handleInvoiceBroadcast(type, payload) {
      if(type == "destroy") return;
      if(payload.invoice_id == this.value || payload.invoice_ids.includes(this.value)) this.fetchInvoice(); 
    },
  },
  channels: {
    InvoiceCreatorChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/payments/invoice.scss';
</style>