<template>
  <div class="review-group">
    <div v-if="value && value.length">
      <div class="review-group__wrapper">
        <div class="review-group__header">
          <div class="review-group__title">{{ title }}</div>
        </div>
        <div class="review-group__body">
          <div
            class="review-item"
            v-for="(group, idx) in value"
            :key="group.id"
          >
            <div class="review-item__top">
              <div class="review-item__title">
                {{ group.title }}
              </div>
              <div class="review-item__status">
                <SubmissionFormStage
                  :submissionForm="group"
                  :value="group.state"
                />
              </div>
            </div>
            <div
              v-if="group.description"
              class="review-item__description"
              v-html="group.description"
            ></div>
            <div class="review-item__dates">
              <div class="review-item__date" v-if="group.opening_date">
                <span style="color: #13b219">OPENING</span>
                <span>
                  {{
                    group.opening_date
                      | moment(
                        "timezone",
                        currentTimezone,
                        "MMM D, YYYY h:mm A zz"
                      )
                  }}
                </span>
              </div>
              <div class="review-item__date" v-if="group.closing_date">
                <span style="color: #e12d1b">CLOSING</span>
                <span>{{
                  group.closing_date
                    | moment(
                      "timezone",
                      currentTimezone,
                      "MMM D, YYYY h:mm A zz"
                    )
                }}</span>
              </div>
            </div>
            <div class="review-item__eligibilities">
              <b>ELIGIBLE</b>
              <Eligibles
                v-if="group.eligibilities.length"
                :value="group.eligibilities"
              />
              <span v-else>All Community Creators</span>
            </div>
            <div class="review-item__eligibilities-mobile">
              <b>ELIGIBLE</b>
              <Eligibles
                v-if="group.eligibilities.length"
                :value="group.eligibilities"
              />
              <span v-else>All Community Creators</span>
            </div>
            <div class="review-item__links">
              <v-btn @click="showSubmission(idx, 0)" color="primary"
                >Review Submissions</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      max-width="1023"
      v-if="submissionDialog"
      v-model="submissionDialog"
      light
    >
      <v-card class="submission-dialog-container">
        <v-btn class="submission-dialog-close" icon @click="cancel">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text class="submission-dialog">
          <div class="submission-dialog__header">
            <div class="submission-dialog__title">
              {{ submissionDialogContent.submission_form.title }}
            </div>
            <div class="submission-dialog__nav">
              <span>
                Submission {{ submissionIndex + 1 }} of
                {{ value[groupIndex].submissions.length }}
              </span>
              <div
                class="submission-dialog__nav__btn"
                @click="clickPrevNext('prev')"
              >
                <v-icon>$chevron_left_filled</v-icon>
              </div>
              <div
                class="submission-dialog__nav__btn"
                @click="clickPrevNext('next')"
              >
                <v-icon>$chevron_right_filled</v-icon>
              </div>

              <v-fade-transition>
                <div
                  class="please-save__tip"
                  width="200"
                  v-if="pleaseSaveDialog"
                >
                  <div
                    class="please-save__tip_click-outside"
                    @click="pleaseSaveDialog = false"
                  ></div>
                  <v-card class="please-save__tip_content">
                    <div>
                      Please save your work below, or changes will be lost.
                    </div>
                    <v-btn
                      class="please-save__btn"
                      color="primary"
                      @click="advanceAnyway"
                      >Advance Anyway</v-btn
                    >
                  </v-card>
                </div>
              </v-fade-transition>
            </div>
          </div>
          <div class="submission-dialog__content" v-if="value[groupIndex]">
            <SubmissionReviewItem
              v-if="submissionDialogContentLayout"
              :item="submissionDialogContent"
              :layout="submissionDialogContentLayout"
              @showVideo="showVideo"
            />
            <div v-else>
              <!-- <div class="submission-dialog__date">
                Submitted
                {{
                  submissionDialogContent.created_at
                    | moment("timezone", currentTimezone, "MMM D, YYYY hh:mm a")
                }}
              </div>
              <div class="submission-dialog__right-title">
                Download uploaded content to view
              </div> -->
              <div class="submission-fields">
                <div class="review-item__user">
                  <div class="review-item__user-name">{{ submissionDialogContent.user.full_name }}</div>
                  <div class="review-item__user-email">
                    <a :href="`mailto:${submissionDialogContent.user.email}`" target="_blank">{{ submissionDialogContent.user.email }}</a>
                  </div>
                </div>
                <component
                  class="submission-field"
                  :key="idx"
                  v-for="(field, idx) in formattedData"
                  :class="'submission-field--' + field.type"
                  :href="field.value.url || field.value"
                  target="_blank"
                  :is="
                    (['MediaField', 'LinkField'].includes(field.type) &&
                      field.value.url) ||
                    field.value
                      ? 'a'
                      : 'div'
                  "
                >
                  <div>
                    <div class="submission-field__title">
                      {{ field.short_name || field.label }}
                    </div>
                    <div
                      class="submission-field__value"
                      v-if="field.type == 'Radio'"
                      v-text="getRadio(field)"
                    ></div>
                    <div
                      class="submission-field__value"
                      v-else-if="field.type != 'MediaField'"
                      v-text="field.value"
                    ></div>
                  </div>
                  <div class="submission-field__icon">
                    <v-icon v-if="field.type == 'MediaField'"
                      >$paperclip</v-icon
                    >
                    <v-icon v-if="field.type == 'LinkField'">$link</v-icon>
                  </div>
                </component>
              </div>
            </div>
          </div>
          <div class="submission-dialog__footer">
            <!-- rating -->
            <div class="submission-dialog__footer_ratings">
              <div
                class="submission-dialog__footer_rating"
                v-for="option in ratings"
                :key="option.value"
                :class="{
                  selected:
                    (pendingRating
                      ? pendingRating
                      : value[groupIndex].submissions[submissionIndex]
                          .rating) == option.value,
                }"
                @click="pendingRating = option.value"
              >
                <div class="submission-dialog__footer_rating-value">
                  {{ option.value }}
                </div>
                <div class="submission-dialog__footer_rating-label">
                  {{ option.label }}
                </div>
              </div>
            </div>
            <div class="submission-dialog__footer-actions">
              <div
                class="submission-dialog__footer_next"
                @click="saveRating(submissionIndex)"
                :class="{
                  disabled:
                    !pendingRating &&
                    !value[groupIndex].submissions[submissionIndex].rating,
                }"
              >
                Save and Go To Next Submission
              </div>
              <div
                class="submission-dialog__footer-action"
                @click="toggleFinalist(submissionIndex)"
                :class="{
                  selected: value[groupIndex].submissions[submissionIndex].finalist
                }"
              >
                Finalist
              </div>
              <div
                class="submission-dialog__footer-action"
                @click="toggleWinner(submissionIndex)"
                :class="{
                  selected: value[groupIndex].submissions[submissionIndex].winner
                }"
              >
                Winner
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="900" v-model="videoDialog" light>
      <v-card class="video__dialog" v-if="videoValue">
        <v-responsive class="video__dialog__video" :aspect-ratio="497 / 280">
          <VimeoPlayer
            v-if="videoValue.vimeo_id"
            :vimeoId="videoValue.vimeo_id"
            :vimeoH="videoValue.vimeo_h"
          />
        </v-responsive>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SubmissionReviewItem from "@/components/review/SubmissionReviewItem.vue";

export default {
  components: { SubmissionReviewItem },
  props: {
    value: Array,
    title: String,
  },
  data() {
    return {
      submissionDialog: false,
      submissionDialogContent: null,
      groupIndex: 0,
      submissionIndex: 0,
      selectedRating: 0,
      pendingRating: 0,
      ratings: [
        {
          value: 1,
          label: "Poor",
        },
        {
          value: 2,
          label: "Decent",
        },
        {
          value: 3,
          label: "Good",
        },
        {
          value: 4,
          label: "Great",
        },
        {
          value: 5,
          label: "Superb",
        },
      ],
      videoDialog: false,
      videoValue: null,
      pleaseSaveDialog: false,
      pleaseSaveDirection: "next",
    };
  },
  methods: {
    showSubmission(groupIdx, submissionIdx) {
      this.groupIndex = groupIdx;
      this.submissionIndex = submissionIdx;
      this.submissionDialogContent =
        this.value[groupIdx].submissions[submissionIdx];
      this.submissionDialog = true;
      this.pendingRating = 0;
    },
    getRadio(field) {
      const formField = this.value[this.groupIndex].submission_form.find((f) => f.uuid == field.uuid);
      if(!formField) return "[Field not found]";
      const option = formField.options.find((o) => o.uuid == field.value);
      return option ? option.title : "[Value not found]";
    },
    async cancel() {
      if (this.pendingRating) {
        const confirmed = await this.$confirm({
          title: "Please save your work below, or changes will be lost.",
        });
        if (!confirmed) return;
      }
      this.submissionDialog = false;
      this.submissionDialogContent = null;
      this.submissionIndex = 0;
      this.pendingRating = 0;
    },
    clickPrevNext(dir) {
      if (this.pleaseSaveDialog) return;
      if (this.pendingRating != 0) {
        this.pleaseSaveDialog = true;
        this.pleaseSaveDirection = dir;
        return;
      }
      this[dir]();
    },
    prev() {
      const group = this.value[this.groupIndex];
      if (!group || group.submissions.length == 0) return;
      const idx =
        (this.submissionIndex + group.submissions.length - 1) %
        group.submissions.length;
      this.showSubmission(this.groupIndex, idx);
    },
    next() {
      const group = this.value[this.groupIndex];
      if (!group || group.submissions.length == 0) return;
      const idx = (this.submissionIndex + 1) % group.submissions.length;
      this.showSubmission(this.groupIndex, idx);
    },
    async saveRating(idx) {
      if (
        this.pendingRating == 0 &&
        this.value[this.groupIndex].submissions[idx].rating == 0
      ) {
        return;
      }

      if (this.pendingRating != 0) {
        this.value[this.groupIndex].submissions[idx].rating =
          this.pendingRating;

        const { id, rating } = this.value[this.groupIndex].submissions[idx];
        const { status } = await this.$api.Submission.saveRating({
          id,
          rating,
        });
        if (status == 200) {
          this.next();
        }
      } else {
        this.next();
      }
    },
    async toggleFinalist(idx) {
      const submission = this.value[this.groupIndex].submissions[idx]
      const finalist = !submission.finalist
      const { id } = submission;
      const { status } = await this.$api.Submission.saveFinalist({
        id,
        finalist,
      });
      if (status == 200) {
        submission.finalist = finalist;
        this.$alert(finalist ? "Submission saved as a finalist." : "Submission saved as not a finalist.")
      }
    },
    async toggleWinner(idx) {
      const submission = this.value[this.groupIndex].submissions[idx]
      const winner = !submission.winner
      const { id } = submission;
      const { status } = await this.$api.Submission.saveWinner({
        id,
        winner,
      });
      if (status == 200) {
        submission.winner = winner;
        this.$alert(winner ? "Submission saved as a winner." : "Submission saved as not a winner.")
      }
    },
    advanceAnyway() {
      this.pendingRating = 0;
      this.pleaseSaveDialog = false;
      if (this.pleaseSaveDirection == "prev") this.prev();
      else this.next();
    },
    showVideo(value) {
      this.videoValue = value;
      this.videoDialog = true;
    },
  },
  computed: {
    formattedData() {
      return this.submissionDialogContent.formatted_data.filter(
        (t) => t.type != "Checkbox"
      );
    },
    submissionDialogContentLayout() {
      const form = this.value[this.groupIndex];
      if (!form || !form.visualization) return;
      return form.visualization.layout[form.id];
    },
  },
  watch: {
    videoDialog(newValue) {
      if (!newValue) {
        this.videoValue = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.review-group {
  &__wrapper {
    margin-top: 50px;
    border-radius: 10px;
    background: white;
  }
  &__header {
    padding: 25px 40px;
    background: white;
    border: 2px solid #262729;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 960px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    color: #e12d1b;
  }
  &__body {
  }
}

.review-item {
  padding: 40px;
  background: white;
  border: 2px solid #262729;
  margin-top: -2px;
  @media screen and (max-width: 960px) {
    padding: 25px 12px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid black;
    padding-bottom: 22px;
    margin-bottom: 19px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 1em;
  }

  &__dates {
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__date {
    font-weight: bold;
    margin-right: 30px;

    span {
      padding: 0 3px 0 0;
    }
  }

  &__eligibilities {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 960px) {
      display: none;
    }

    b {
      margin-right: 7px;
      color: #56565a;
    }
  }
  &__eligibilities-mobile {
    display: none;

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 17px;
      margin-right: -20px;
      font-size: 14px;
      > b {
        margin-bottom: 11px;
      }
      .eligibles {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__links {
    margin-top: 15px;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    color: #262729;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__status {
    flex-basis: 260px;
  }

  &__user {
    color: #262729 !important;
    &-name {
      font-weight: bold;
      font-size: 14px;
    }
    a {
      color: #262729 !important;
      text-decoration: none;
    }
    &-email {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 300;
    }
  }
}

.submission-dialog {
  display: flex;
  flex-direction: column;
  height: 100px;
  flex-grow: 1;

  &-container {
    border: 2px solid #262729;
    border-radius: 10px;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    > .v-card__text {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &__header,
  &__footer_ratings {
    border-bottom: 2px solid #262729;
  }

  &__header,
  &__content {
    padding: 30px !important;
  }

  &__content {
    height: 100px;
    overflow: auto;
    flex-grow: 1;
  }

  &__title {
    color: #262729;
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
  }

  &__nav {
    display: flex;
    position: relative;
    width: max-content;
    &__btn:hover {
      cursor: pointer;
    }
  }

  &__date {
    color: #262729;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
  }

  &__left {
    width: 50%;
  }
  &__right {
    width: 50%;
    &-title {
      color: #26272977;
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      width: 100%;
    }
  }

  &__footer {
    border-top: 2px solid #262729;
    color: #262729;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    &_ratings {
      display: flex;
      margin: 0 -2px 0 0;
    }
    &_rating {
      flex-basis: 20%;
      flex-grow: 1;
      border-style: solid;
      border-color: #262729;
      border-width: 0 2px 0 0;
      padding: 13px;
      color: #262729;
      &.selected {
        background: var(--v-primary-base);
        color: white;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-actions {
      display: flex;
    }
    &-action {
      padding: 28px 10px;
      border-left: 2px solid black;
      cursor: pointer;
      &.selected {
        background: var(--v-primary-base);
        color: white;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    &_next {
      padding: 28px;
      flex-grow: 1;
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        opacity: 0.5;
        &:hover {
          cursor: default;
        }
      }
    }
  }
}

.submission-field {
  text-decoration: none;
  background: white;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #262729;
  text-decoration: none;
  margin-bottom: 1.43em;

  &--TextArea {
    height: auto;
  }
  &--TextArea &__value {
    white-space: pre-wrap;
  }

  .v-icon {
    color: #262729;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }
  &__value {
    position: relative;
    top: -4px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 300;
  }
}

.video__dialog {
  background: black;
}

.please-save {
  &__tip {
    position: absolute;
    top: calc(100% + 0.25em);
    width: 200px;
    right: calc(24px - 100px);
    z-index: 1;

    &_click-outside {
      content: "";
      display: block;
      background: rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
    }

    &_content {
      text-align: center;
      border: 2px solid black;
      border-radius: 10px;
      padding: 23px 9px 27px;
      z-index: 2;
      > div {
        margin-bottom: 1em;
      }
    }
  }
  &__btn {
    font-weight: 700;
  }
}
@media screen and (max-width: 960px) {
  .submission-dialog__footer_ratings {
    flex-wrap: wrap;
  }

  .submission-dialog__footer_ratings
    > .submission-dialog__footer_rating:last-child {
    border-width: 2px 2px 0 0;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 960px) {
  .review-item__eligibilities-mobile .eligible {
    margin-bottom: 18px;
  }
}
</style>
