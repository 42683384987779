var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.onboardingDetails)?_c('div',{staticClass:"ob__alerts"},[(
      _vm.onboardingDetails.msa_status == 'NONE' ||
      _vm.onboardingDetails.w9_status == 'NONE' ||
      _vm.onboardingDetails.ach_status == 'NONE' ||
      _vm.onboardingDetails.gig_contract_needed
    )?_c('div',{staticClass:"ob__alert ob__alert--error"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('img',_vm._g({staticClass:"ob__icon",attrs:{"src":require("@/assets/onboarding-alerts/needs-signature.svg"),"alt":"Signature needed alert."}},on))]}}],null,false,3478543370)},[_c('div',[(_vm.onboardingDetails.msa_status == 'NONE')?_c('div',[_vm._v(" Creator has not signed a current-year MSA. ")]):_vm._e(),(_vm.onboardingDetails.w9_status == 'NONE')?_c('div',[_vm._v(" Creator has not filled out a W-9. ")]):_vm._e(),(_vm.onboardingDetails.ach_status == 'NONE')?_c('div',[_vm._v(" Creator has not filled out an ACH authorization. ")]):_vm._e(),(_vm.onboardingDetails.gig_contract_needed)?_c('div',[_vm._v(" Creator has gig(s) that need a contract. ")]):_vm._e()])])],1):_vm._e(),(!_vm.onboardingDetails.qb_is_linked)?_c('div',{staticClass:"ob__alert ob__alert--error"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('img',_vm._g({staticClass:"ob__icon",attrs:{"src":require("@/assets/onboarding-alerts/no-qbo-vendor.svg"),"alt":"Broken link alert"}},on))]}}],null,false,3624185885)},[_c('div',[_vm._v("Creator is not linked to a QBO vendor.")])])],1):_vm._e(),(
      _vm.onboardingDetails.msa_status == 'OUT_FOR_SIGNATURE' ||
      _vm.onboardingDetails.w9_status == 'OUT_FOR_SIGNATURE' ||
      _vm.onboardingDetails.ach_status == 'OUT_FOR_SIGNATURE' ||
      _vm.onboardingDetails.gig_contract_signature_needed ||
      _vm.onboardingDetails.gig_extension_signature_needed
    )?_c('div',{staticClass:"ob__alert ob__alert--msg"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('img',_vm._g({staticClass:"ob__icon",attrs:{"src":require("@/assets/onboarding-alerts/out-for-signature.svg"),"alt":"Out for signature alert"}},on))]}}],null,false,2095863675)},[_c('div',[(_vm.onboardingDetails.msa_status == 'OUT_FOR_SIGNATURE')?_c('div',[_vm._v(" MSA out for signature. ")]):_vm._e(),(_vm.onboardingDetails.w9_status == 'OUT_FOR_SIGNATURE')?_c('div',[_vm._v(" W-9 out for signature. ")]):_vm._e(),(_vm.onboardingDetails.ach_status == 'OUT_FOR_SIGNATURE')?_c('div',[_vm._v(" ACH authorization out for signature. ")]):_vm._e(),(_vm.onboardingDetails.gig_contract_signature_needed)?_c('div',[_vm._v(" Creator has a gig contract that needs a signature. ")]):_vm._e(),(_vm.onboardingDetails.gig_extension_signature_needed)?_c('div',[_vm._v(" Creator has a gig extension that needs a signature. ")]):_vm._e()])])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }