<template>
  <div class="creator-item">
    <div class="creator-item__body">
      <router-link
        :to="`/p/${value.username || value.id}`"
        class="creator-item__preview"
        :style="{ backgroundImage }"
      >
        <Availability :value="value.availability" />
        <div class="creator-item__badges">
          <v-tooltip
            v-for="(badge, idx) in value.badges"
            :key="idx"
            open-delay="500"
            max-width="300"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <router-link
                  class="creator-item__badge"
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    left: -idx * 15 + 'px',
                    backgroundImage: `url(${badge.preview})`,
                  }"
                  to="/badge-info"
                  target="_blank"
                ></router-link>
              </div>
            </template>
            <span>{{ badge.title }}</span>
          </v-tooltip>
        </div>
      </router-link>
      <div class="creator-item__name">
        {{ value.full_name || value.reduced_name }}
      </div>
      <div class="creator-item__role">
        {{ value.primary_role }}
      </div>
      <div class="creator-item__location">
        {{ value.location }}
      </div>
    </div>
    <CreatorOnboardingAlerts
      :onboardingDetails="value.onboarding_details"
      class="creator-item__alerts"
    />
    <div class="creator-item__actions">
      <v-btn
        :to="`/p/${value.username || value.id}/payments`"
        class="creator-item__action creator-item__action--red"
        v-if="hmActions"
        elevation="0"
        >View Details</v-btn
      >
      <router-link
        v-else
        :to="`/p/${value.username || value.id}`"
        class="creator-item__action creator-item__action--red"
        >View</router-link
      >
      <div
        v-if="removeReviewRequest"
        @click="removeReviewRequestDialog = true"
        class="creator-item__action"
      >
        Remove
      </div>
    </div>
    <CreatorActionsMenu
      class="creator-item__admin-actions-toggle"
      v-if="
        currentUser.is_reviewer ||
        currentUser.is_hiring_manager ||
        currentUser.is_finance ||
        currentUser.is_people_team
      "
      :value="value"
      @close="
        creatorActionsMenu = false;
        removeReviewRequestDialog = false;
      "
      @toggle="creatorActionsMenu = !creatorActionsMenu"
      :menu="creatorActionsMenu"
      @reviewProfile="reviewProfile($event)"
      @refresh="
        creatorActionsMenu = false;
        removeReviewRequestDialog = false;
        $emit('refresh');
      "
    >
    </CreatorActionsMenu>
    <v-dialog v-model="removeReviewRequestDialog" width="400">
      <v-card class="remove-review-request-dialog">
        <v-btn class="close" icon @click="removeReviewRequestDialog = false">
          <v-icon size="30">$close</v-icon>
        </v-btn>
        <div class="remove-review-request-dialog__header">
          Remove Request to Review Creator
        </div>
        <div class="remove-review-request-dialog__content">
          <p>Are you sure?</p>
          <v-btn @click="removeReviewRequestConfirm(value.id)">Yes</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import CreatorOnboardingAlerts from "@/components/work-assignments/CreatorOnboardingAlerts";

export default {
  mixins: [themeDisabledPageMixin],
  components: { CreatorOnboardingAlerts },
  props: {
    value: Object,
    removeReviewRequest: Boolean,
    hmActions: Boolean,
  },
  data() {
    return {
      backgroundImage: this.value.avatar.url
        ? `url(${this.value.avatar.thumb.url})`
        : `url("data:image/svg+xml,%3Csvg width='158' height='158' viewBox='0 0 158 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M157.214 79.2449C157.214 122.572 122.09 157.696 78.7629 157.696C35.4357 157.696 0.312012 122.572 0.312012 79.2449C0.312012 35.9176 35.4357 0.793945 78.7629 0.793945C122.09 0.793945 157.214 35.9176 157.214 79.2449Z' fill='%23DCDCE1'/%3E%3Cpath opacity='0.3' d='M99 44C99 55.0457 90.0457 64 79 64C67.9543 64 59 55.0457 59 44C59 32.9543 67.9543 24 79 24C90.0457 24 99 32.9543 99 44Z' fill='%23262729'/%3E%3Cpath opacity='0.3' fill-rule='evenodd' clip-rule='evenodd' d='M28.9999 79C28.9999 106.614 51.3857 129 78.9999 129C106.614 129 129 106.614 129 79L28.9999 79Z' fill='%23262729'/%3E%3C/svg%3E")`,
      creatorActionsMenu: false,
      removeReviewRequestDialog: false,
    };
  },
  methods: {
    reviewProfile(id) {
      this.creatorActionsMenu = false;
      this.$emit("reviewProfile", id);
    },
    async removeReviewRequestConfirm(id) {
      const res = await this.$api.Creator.removeReviewRequest({
        id,
      });

      if (res.status == 200) {
        this.removeReviewRequestDialog = false;
        this.$emit("remove");
      }
    },
  },
  watch: {
    creatorActionsMenu(val) {
      if (!val) {
        this.adminAccessMenu = null;
        this.assigneeId = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.creator-item {
  width: calc(20% + 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
  border: 2px solid var(--v-dark-base);
  margin-left: -2px;
  margin-bottom: -2px;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 960px) {
    width: 100%;
    border-right: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__badges {
    position: absolute;
    left: 100%;
    margin-left: -27px;
    top: 10px;
    display: flex;
  }
  &__badge {
    width: 25px;
    height: 25px;
    background-size: calc(100% + 2px);
    background-position: center;
    position: relative;
    border-radius: 50%;
    background-color: white;
    display: inline-block;
    &:hover {
      z-index: 1;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  &__action {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 0 10px;
    border-radius: 10px;
    text-decoration: none;
    background: var(--v-text-base);
    color: var(--v-background-base);

    &:not(:last-child) {
      margin-right: 0.5em;
    }

    &--red {
      background: #e12d1b;
    }
  }

  &__name {
    color: var(--v-dark-base);
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
  }

  &__location {
    color: #e12d1b;
    white-space: break-spaces;
  }

  &__preview {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    position: relative;

    &::v-deep {
      .availability {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 9px);
      }
    }
  }

  &__admin-actions-toggle {
    position: absolute;
    top: 16px;
    right: 11px;
  }
  &__admin-actions {
    position: relative;
    > .v-icon {
      display: block;

      &.theme--dark ~ .creator-item__admin-actions-menu {
        background-color: var(--v-secondary-base);
      }
    }
    &-menu {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      width: 245px;
      border-radius: 10px;
      text-align: center;
      box-shadow: none;
      &-content {
        position: relative;
        background: var(--v-background-base);
      }
      .dialog-close {
        position: absolute;
        top: 0;
        right: 0;
      }
      .v-btn {
        font-weight: bold;

        &.theme--dark + .creator-item__admin-actions-menu-header {
          background: var(--v-secondary-darken1);
        }
      }
      &-header {
        width: 100%;
        background: white;
        min-height: 56px;
        border-radius: 10px 10px 0 0;
        font-size: 20px;
        font-weight: 900;
        padding: 12px;
      }
      &__main,
      &__assign {
        .v-btn:not(.v-btn--icon) {
          min-width: 131px;
          padding: 0 10px;
        }
      }
      &__main {
        padding-top: 55px;
        padding-bottom: 49px;
        .v-btn:not(.v-btn--icon):not(:last-child) {
          margin-bottom: 28px;
        }
      }
      &__assign {
        padding-top: 37px;
        padding-bottom: 49px;
        .v-btn:not(.v-btn--icon):not(:last-child) {
          margin-bottom: 13px;
        }
        &__send::v-deep {
          &.theme--light.v-btn.v-btn--disabled,
          &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
            color: white !important;
            background-color: var(--v-primary-base) !important;
            opacity: 0.5;
          }
        }
      }
      &__review-assignee-select {
        border: 1px solid #dcdce1;
        border-radius: 10px;
        max-width: 170px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
        &::v-deep {
          &.tag-selector.v-input fieldset {
            border: none;
          }
          &.v-text-field--outlined > .v-input__control > .v-input__slot {
            min-height: 40px !important;
          }
          &.v-text-field--outlined.v-input--dense .v-label {
            top: 10px;
          }
          &.tag-selector.v-text-field--enclosed .v-input__append-inner {
            margin-top: 8px !important;
          }
        }
      }
      p {
        margin-left: 1em;
        margin-right: 1em;
      }
    }
  }

  &__alerts {
    top: 0px;
    right: 35px;
    z-index: 1;
  }
}

.disabled-theme {
  .creator-item {
    border-color: #262729;

    &__name {
      color: #262729;
    }
  }

  .creator-item__action {
    background: #262729 !important;
    color: #f8f8f9 !important;
    &--red {
      background: #e12d1b;
      color: white;
    }
  }

  .creator-item__admin-actions-menu {
    &-content {
      background: #dcdce1;
    }

    .v-btn.theme--dark + * {
      background: none;
    }
  }
}
</style>

<style lang="scss">
.creators-section__list-swiper .creator-item {
  margin-right: -2px;
  border: none;
}

.creator-item__admin-actions__review-assignee-select fieldset {
  border: none !important;
}

.remove-review-request-dialog {
  text-align: center;
  color: #56565a;
  &__header {
    font-weight: 900;
    padding: 1em 3em;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 3em 2em;
    background: #dcdce1;
  }
}

.mail-icon {
  transform: skew(-10deg);
}
</style>
