var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"tag-selector",class:{
    multiple: _vm.multiple,
    'hidden-input': _vm.multiple && _vm.maximum && _vm.value && _vm.value.length >= _vm.maximum,
  },attrs:{"value":_vm.value,"items":_vm.items,"chips":_vm.multiple,"outlined":"","min-height":"40","label":_vm.label,"multiple":_vm.multiple,"search-input":_vm.localSearch,"item-text":_vm.itemText,"item-value":_vm.itemValue,"deletable-chips":_vm.multiple,"clearable":!_vm.multiple,"hide-details":"auto","rules":_vm.rules,"menu-props":{
    closeOnContentClick:
      _vm.multiple && _vm.maximum && _vm.value && _vm.value.length >= _vm.maximum,
    light: _vm.light,
  },"dense":"","append-icon":!_vm.appendIcon && _vm.appendIcon !== '' ? '$chevron_down' : _vm.appendIcon,"placeholder":_vm.placeholder,"elevation":"0","light":_vm.light,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)},"update:searchInput":function($event){_vm.localSearch=$event},"update:search-input":function($event){_vm.localSearch=$event}}})}
var staticRenderFns = []

export { render, staticRenderFns }