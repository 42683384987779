<template>
  <div class="profile-rating" :class="{'empty': count == 0}">
    <div class="profile-rating__star"
      v-for="i in 5"
      :key="i"
      :class="{ 'filled': i <= value, clickable}"
      @click="clickRating(i)"
    ></div>
  </div>
</template>

<script>
export default ({
  name: "ProfileRating",
  props: {
    value: Number,
    count: Number,
    clickable: Boolean,
  },
  methods: {
    clickRating(i) {
      if(this.clickable) this.$emit("input", i);
    }
  },
})
</script>

<style lang="scss" scoped>
.profile-rating {
  display: flex;
  &__star {
    width: 19px;
    height: 19px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &.clickable {
      cursor: pointer;
    }
  }
  &.empty {
    opacity: 0.25;
  }
}
</style>
<style lang="scss">
.theme--dark .profile-rating__star {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNIDE3LjkxOCA3LjAyNSBMIDEyLjUyMiA2LjI2MSBMIDEwLjExIDEuNDk4IEMgMTAuMDQ0IDEuMzY4IDkuOTM2IDEuMjYyIDkuODAyIDEuMTk4IEMgOS40NjYgMS4wMzcgOS4wNTggMS4xNzEgOC44OSAxLjQ5OCBMIDYuNDc4IDYuMjYxIEwgMS4wODIgNy4wMjUgQyAwLjkzMyA3LjA0NSAwLjc5NyA3LjExNCAwLjY5MyA3LjIxNyBDIDAuNTY3IDcuMzQzIDAuNDk4IDcuNTEzIDAuNSA3LjY4OCBDIDAuNTAyIDcuODY0IDAuNTc2IDguMDMyIDAuNzA2IDguMTU1IEwgNC42MSAxMS44NjIgTCAzLjY4NyAxNy4wOTYgQyAzLjY2NiAxNy4yMTggMy42OCAxNy4zNDMgMy43MjcgMTcuNDU4IEMgMy43NzUgMTcuNTcyIDMuODU1IDE3LjY3MSAzLjk1NyAxNy43NDQgQyA0LjA2IDE3LjgxNyA0LjE4MiAxNy44NiA0LjMwOCAxNy44NjkgQyA0LjQzNSAxNy44NzcgNC41NjEgMTcuODUxIDQuNjc0IDE3Ljc5NCBMIDkuNSAxNS4zMjIgTCAxNC4zMjYgMTcuNzk0IEMgMTQuNDU4IDE3Ljg2MiAxNC42MTEgMTcuODg1IDE0Ljc1OCAxNy44NiBDIDE1LjEyOCAxNy43OTggMTUuMzc2IDE3LjQ1NiAxNS4zMTMgMTcuMDk2IEwgMTQuMzkgMTEuODYyIEwgMTguMjk0IDguMTU1IEMgMTguNDAxIDguMDUzIDE4LjQ3MSA3LjkyMSAxOC40OTIgNy43NzYgQyAxOC41NDkgNy40MTQgMTguMjkgNy4wNzggMTcuOTE4IDcuMDI1IFogTSAxMi43NDcgMTEuMzQgTCAxMy41MTUgMTUuNjkzIEwgOS41IDEzLjY0IEwgNS40ODUgMTUuNjk1IEwgNi4yNTMgMTEuMzQyIEwgMy4wMDUgOC4yNTggTCA3LjQ5NCA3LjYyMyBMIDkuNSAzLjY2MyBMIDExLjUwNiA3LjYyMyBMIDE1Ljk5NSA4LjI1OCBMIDEyLjc0NyAxMS4zNCBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=");

  &.filled {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNIDE3LjkxOCA3LjAyNSBMIDEyLjUyMiA2LjI2MSBMIDEwLjExIDEuNDk4IEMgMTAuMDQ0IDEuMzY4IDkuOTM2IDEuMjYyIDkuODAyIDEuMTk4IEMgOS40NjYgMS4wMzcgOS4wNTggMS4xNzEgOC44OSAxLjQ5OCBMIDYuNDc4IDYuMjYxIEwgMS4wODIgNy4wMjUgQyAwLjkzMyA3LjA0NSAwLjc5NyA3LjExNCAwLjY5MyA3LjIxNyBDIDAuNTY3IDcuMzQzIDAuNDk4IDcuNTEzIDAuNSA3LjY4OCBDIDAuNTAyIDcuODY0IDAuNTc2IDguMDMyIDAuNzA2IDguMTU1IEwgNC42MSAxMS44NjIgTCAzLjY4NyAxNy4wOTYgQyAzLjY2NiAxNy4yMTggMy42OCAxNy4zNDMgMy43MjcgMTcuNDU4IEMgMy43NzUgMTcuNTcyIDMuODU1IDE3LjY3MSAzLjk1NyAxNy43NDQgQyA0LjA2IDE3LjgxNyA0LjE4MiAxNy44NiA0LjMwOCAxNy44NjkgQyA0LjQzNSAxNy44NzcgNC41NjEgMTcuODUxIDQuNjc0IDE3Ljc5NCBMIDkuNSAxNS4zMjIgTCAxNC4zMjYgMTcuNzk0IEMgMTQuNDU4IDE3Ljg2MiAxNC42MTEgMTcuODg1IDE0Ljc1OCAxNy44NiBDIDE1LjEyOCAxNy43OTggMTUuMzc2IDE3LjQ1NiAxNS4zMTMgMTcuMDk2IEwgMTQuMzkgMTEuODYyIEwgMTguMjk0IDguMTU1IEMgMTguNDAxIDguMDUzIDE4LjQ3MSA3LjkyMSAxOC40OTIgNy43NzYgQyAxOC41NDkgNy40MTQgMTguMjkgNy4wNzggMTcuOTE4IDcuMDI1IFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
  }
}
.theme--light .profile-rating__star {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNIDE3LjkxOCA3LjAyNSBMIDEyLjUyMiA2LjI2MSBMIDEwLjExIDEuNDk4IEMgMTAuMDQ0IDEuMzY4IDkuOTM2IDEuMjYyIDkuODAyIDEuMTk4IEMgOS40NjYgMS4wMzcgOS4wNTggMS4xNzEgOC44OSAxLjQ5OCBMIDYuNDc4IDYuMjYxIEwgMS4wODIgNy4wMjUgQyAwLjkzMyA3LjA0NSAwLjc5NyA3LjExNCAwLjY5MyA3LjIxNyBDIDAuNTY3IDcuMzQzIDAuNDk4IDcuNTEzIDAuNSA3LjY4OCBDIDAuNTAyIDcuODY0IDAuNTc2IDguMDMyIDAuNzA2IDguMTU1IEwgNC42MSAxMS44NjIgTCAzLjY4NyAxNy4wOTYgQyAzLjY2NiAxNy4yMTggMy42OCAxNy4zNDMgMy43MjcgMTcuNDU4IEMgMy43NzUgMTcuNTcyIDMuODU1IDE3LjY3MSAzLjk1NyAxNy43NDQgQyA0LjA2IDE3LjgxNyA0LjE4MiAxNy44NiA0LjMwOCAxNy44NjkgQyA0LjQzNSAxNy44NzcgNC41NjEgMTcuODUxIDQuNjc0IDE3Ljc5NCBMIDkuNSAxNS4zMjIgTCAxNC4zMjYgMTcuNzk0IEMgMTQuNDU4IDE3Ljg2MiAxNC42MTEgMTcuODg1IDE0Ljc1OCAxNy44NiBDIDE1LjEyOCAxNy43OTggMTUuMzc2IDE3LjQ1NiAxNS4zMTMgMTcuMDk2IEwgMTQuMzkgMTEuODYyIEwgMTguMjk0IDguMTU1IEMgMTguNDAxIDguMDUzIDE4LjQ3MSA3LjkyMSAxOC40OTIgNy43NzYgQyAxOC41NDkgNy40MTQgMTguMjkgNy4wNzggMTcuOTE4IDcuMDI1IFogTSAxMi43NDcgMTEuMzQgTCAxMy41MTUgMTUuNjkzIEwgOS41IDEzLjY0IEwgNS40ODUgMTUuNjk1IEwgNi4yNTMgMTEuMzQyIEwgMy4wMDUgOC4yNTggTCA3LjQ5NCA3LjYyMyBMIDkuNSAzLjY2MyBMIDExLjUwNiA3LjYyMyBMIDE1Ljk5NSA4LjI1OCBMIDEyLjc0NyAxMS4zNCBaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4=");
  &.filled {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNIDE3LjkxOCA3LjAyNSBMIDEyLjUyMiA2LjI2MSBMIDEwLjExIDEuNDk4IEMgMTAuMDQ0IDEuMzY4IDkuOTM2IDEuMjYyIDkuODAyIDEuMTk4IEMgOS40NjYgMS4wMzcgOS4wNTggMS4xNzEgOC44OSAxLjQ5OCBMIDYuNDc4IDYuMjYxIEwgMS4wODIgNy4wMjUgQyAwLjkzMyA3LjA0NSAwLjc5NyA3LjExNCAwLjY5MyA3LjIxNyBDIDAuNTY3IDcuMzQzIDAuNDk4IDcuNTEzIDAuNSA3LjY4OCBDIDAuNTAyIDcuODY0IDAuNTc2IDguMDMyIDAuNzA2IDguMTU1IEwgNC42MSAxMS44NjIgTCAzLjY4NyAxNy4wOTYgQyAzLjY2NiAxNy4yMTggMy42OCAxNy4zNDMgMy43MjcgMTcuNDU4IEMgMy43NzUgMTcuNTcyIDMuODU1IDE3LjY3MSAzLjk1NyAxNy43NDQgQyA0LjA2IDE3LjgxNyA0LjE4MiAxNy44NiA0LjMwOCAxNy44NjkgQyA0LjQzNSAxNy44NzcgNC41NjEgMTcuODUxIDQuNjc0IDE3Ljc5NCBMIDkuNSAxNS4zMjIgTCAxNC4zMjYgMTcuNzk0IEMgMTQuNDU4IDE3Ljg2MiAxNC42MTEgMTcuODg1IDE0Ljc1OCAxNy44NiBDIDE1LjEyOCAxNy43OTggMTUuMzc2IDE3LjQ1NiAxNS4zMTMgMTcuMDk2IEwgMTQuMzkgMTEuODYyIEwgMTguMjk0IDguMTU1IEMgMTguNDAxIDguMDUzIDE4LjQ3MSA3LjkyMSAxOC40OTIgNy43NzYgQyAxOC41NDkgNy40MTQgMTguMjkgNy4wNzggMTcuOTE4IDcuMDI1IFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPg==");
  }
}
</style>
